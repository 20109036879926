

<script>
    var Parr = []
    var per = window.localStorage.getItem("Pers")
    Parr = per.split(",")
    // console.log(Parr)
    export default {
            getData(value){
                for(var i = 0; i < Parr.length; i++){
                    if(value === Parr[i]){
                        return true;
                    }
                }
                return false;
            }
        
    }
</script>
