
<template>
  <div class="BaseTable4">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="1">人员管理</el-menu-item>
        <el-menu-item index="2">部门管理</el-menu-item>
        <el-menu-item index="3">角色管理</el-menu-item>
        <el-menu-item index="4">字段管理</el-menu-item>
      </el-menu>
      <div class="Men_1">
        销售线索：① 管理系统目前所有的销售线索，可以进行编辑、删除、员工认领；② 可以通过本频道手动添加或批量上传销售线索，用于员工联系与跟踪
      </div> 
    </div>


    <div>
      <div class="Tables">
        <div class="Tab_T" @click="Add_kq(2)" v-if="TextMoMo('company:fields:add')"><img class="Img_ti" src="../../assets/img/+++.png" alt=""> 新增字段</div>
        <div style="float:right">
          <div class="el_seso"><i class="el-icon-search" @click="inpu_sech"></i></div>
          <div class="dsds">
            <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()"></el-input>
          </div>
        </div> 
        <el-table
          :data="ZMent"
          class="table"
          style="width: 100%"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55"
            align="center"></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            width="60"
            align="center"></el-table-column>
          <el-table-column align="center" prop="title" label="字段名称"></el-table-column>
          <el-table-column align="center" prop="type_text" label="类型"></el-table-column>
          <el-table-column align="center" label="必填">
            <template #default="scope">
              <div>
                <el-switch
                  v-model="scope.row.is_require"
                  active-color="#1890FF"
                  inactive-color="#dcdfe6"
                  :active-value = 1
                  :inactive-value = 0
                  @change="Switch1(scope.row,)">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="员工可见" >
            <template #default="scope">
              <div>
                <el-switch
                  v-model="scope.row.is_show"
                  active-color="#1890FF"
                  inactive-color="#dcdfe6"
                  :active-value = 1
                  :inactive-value = 0
                  @change="Switch2(scope.row)">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="员工可编辑">
            <template #default="scope">
              <div>
                <el-switch
                  v-model="scope.row.is_edit"
                  active-color="#1890FF"
                  inactive-color="#dcdfe6"
                  :active-value = 1
                  :inactive-value = 0
                  @change="Switch3(scope.row)">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="400">
            <template #default="scope" >
                <div class="el_butto" style="margin-right:15px">
                  <el-button type="text" @click="Edit_xinz(scope.row)" v-if="TextMoMo('company:fields:read')">查看</el-button>
                  <el-button type="text" v-if="!TextMoMo('company:fields:read')">查看</el-button>
                </div>
                <div class="el_butto">
                  <el-button type="text" @click="Edit_xinz(scope.row)" v-if="TextMoMo('company:fields:edit')">编辑</el-button>
                </div>
                <div class="el_butto rere" >
                    <el-button style="background:#1890FF"
                    type="text"
                    class="red"
                    @click="Din_xinz(scope.row)"
                    v-if="scope.row.status == 'normal' "
                    >停用</el-button>

                  <el-button style="background:#1890FF"
                    v-if="scope.row.status == 'hidden' "
                    type="text"
                    class="red"
                    @click="Din_xinz(scope.row)"
                    >启用</el-button>
                </div>
                <div class="el_butto rere">
                    <el-button
                    type="text" v-if="TextMoMo('company:fields:del')"
                    class="red"
                    @click="Del_xinz(scope.row)"
                    >删除</el-button>
                </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total1">
          </el-pagination>
        </div>
      </div>      
    </div>

    <!--新增字段-->
    <el-dialog title="新增/编辑字段" v-model="dialogVisible2" width="25%">
      <div class="Xians">
        <span class="isddss" style="margin-bottom:12px"><span style="color:#FF4D4F">* </span>字段名称：</span>
        <el-input v-model="Row3.name" placeholder="请输入字段名称"></el-input>
      </div>
      <div class="Xians">
        <span class="isddss" style="margin-bottom:12px"><span style="color:#FF4D4F">* </span>字段标题：</span>
        <el-input v-model="Row3.title" placeholder="请输入字段标题"></el-input>
      </div>
      <div class="isdd">
        <span class="isddss" style="margin-bottom:12px">字段类型：</span>
          <el-select v-model="Type_List_text" placeholder="请选择" @change="Type_Chan" style="width: 100%;">
              <el-option v-for="item in Type_List" :key="item" :label="item" :value="item"></el-option>
          </el-select>
      </div>
      <div class="Xians isdd">
        <span class="isddss" style="margin-bottom:12px">提示语：</span>
        <el-input v-model="Row3.tip" placeholder="请输入备注文字，以让人很好理解如何录入"></el-input>
      </div>
      <div class="Xians isdd dsdsd">
        <span class="isddss" style="margin-bottom:12px">选项内容：</span>
        <div style="overflow:hidden;float: left;width: 100%;">
          <el-input v-for="(item,i) in R3_inou" :key="i" v-model="item.content" :placeholder="item.name"></el-input>
        </div>
      </div>
      <div class="Icci" @click="Add_cent">
        <i class="el-icon-circle-plus-outline"></i><span>添加选项内容</span>
      </div>
      <div class="Xians isdd">
        <span class="dwwwasd">是否为必填项：</span>
        <div>
            <el-radio v-model="Row3.is_require" :label="0">必填项</el-radio>
            <el-radio v-model="Row3.is_require" :label="1">必填项</el-radio>
        </div>
      </div>
      <div class="Xians isdd">
        <span class="dwwwasd">员工是否可见：</span>
        <div>
            <el-radio v-model="Row3.is_show" :label="0">员工可见</el-radio>
            <el-radio v-model="Row3.is_show" :label="1">员工不可见</el-radio>
        </div>
      </div>
      <div class="Xians isdd" style="margin-left:0px">
        <span class="dwwwasd">员工是否可编辑：</span>
        <div>
            <el-radio v-model="Row3.is_edit" :label="0">员工可编辑</el-radio>
            <el-radio v-model="Row3.is_edit" :label="1">员工不可编辑</el-radio>
        </div>
      </div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="BTN_ziduan(1)" v-if="Ad2_ziduan == '' " style="margin-left:15px;">保存设置</el-button>
          <el-button type="primary" @click="BTN_ziduan(2)" v-if="Ad2_ziduan == 'xin' " style="margin-left:15px;">确认新增</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--新增字段-->

    <!--新增字段-->
    <el-dialog title="查看字段" v-model="dialogVisible22" width="25%">
      <div class="Xians">
        <span class="isddss" style="margin-bottom:12px"><span style="color:#FF4D4F">* </span>字段名称：</span>
        <el-input v-model="Row3.name" placeholder="请输入字段名称" disabled></el-input>
      </div>
      <div class="Xians">
        <span class="isddss" style="margin-bottom:12px"><span style="color:#FF4D4F">* </span>字段标题：</span>
        <el-input v-model="Row3.title" placeholder="请输入字段标题" disabled></el-input>
      </div>
      <div class="isdd">
        <span class="isddss" style="margin-bottom:12px">字段类型：</span>
          <el-select v-model="Type_List_text" placeholder="请选择" @change="Type_Chan" disabled style="width: 100%;">
              <el-option v-for="item in Type_List" :key="item" :label="item" :value="item"></el-option>
          </el-select>
      </div>
      <div class="Xians isdd">
        <span class="isddss" style="margin-bottom:12px">提示语：</span>
        <el-input v-model="Row3.tip" placeholder="请输入备注文字，以让人很好理解如何录入" disabled></el-input>
      </div>
      <div class="Xians isdd dsdsd">
        <span class="isddss" style="margin-bottom:12px">选项内容：</span>
        <div style="overflow:hiddenfloat: left;width: 100%;">
          <el-input v-for="(item,i) in R3_inou" :key="i" v-model="item.content" :placeholder="item.name" disabled></el-input>
        </div>

      </div>
      <div class="Icci" @click="Add_cent">
        <i class="el-icon-circle-plus-outline"></i><span>添加选项内容</span>
      </div>
      <div class="Xians isdd">
        <span class="dwwwasd">是否为必填项：</span>
        <div>
            <el-radio v-model="Row3.is_require" :label="0" disabled>必填项</el-radio>
            <el-radio v-model="Row3.is_require" :label="1" disabled>必填项</el-radio>
        </div>
      </div>
      <div class="Xians isdd">
        <span class="dwwwasd">员工是否可见：</span>
        <div>
            <el-radio v-model="Row3.is_show" :label="0" disabled>员工可见</el-radio>
            <el-radio v-model="Row3.is_show" :label="1" disabled>员工不可见</el-radio>
        </div>
      </div>
      <div class="Xians isdd" style="margin-left:0px">
        <span class="dwwwasd">员工是否可编辑：</span>
        <div>
            <el-radio v-model="Row3.is_edit" :label="0" disabled>员工可编辑</el-radio>
            <el-radio v-model="Row3.is_edit" :label="1" disabled>员工不可编辑</el-radio>
        </div>
      </div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="dialogVisible22 = false">取 消</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--新增字段-->
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";  

export default {
  name: "basetable",
  data() {
    return {
      Bmen_Sj1:'',
      Bmen_Sj2:'',
      Bmmm:false,
      fForm:{
        name:'',
        phone:'',
        staff_nums:'',
      }, 
      ziduan_id:0,
      Ad2_ziduan:'',
      FAFA_l:false,
      FAFA_2:false,
      ZMent:[],
      DMent:[],
      BMent:[],
      tag_obj:[],
      Fond_tag:null,
      MMD:[],
      D_input:'', // 搜索部门
      C_input:'', // 搜索成员
      activeIndex: "4",
      more:'../../assets/img/more.png',
      show1:false,
      show2:false,
      show3:false,
      input: "",
      num:'',
      editVisible: false,
      dialogVisible2: false,
      dialogVisible22: false,

      form: {},
      id: -1,
      total:0,
      total1:0,
      total2:0,
      total3:0,
      currentPage1:0,

      Dy:true,
      tot:0,  //分页

      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      
      department_id:0, //部门id

      aTY: 1,        //图片显示判断
          //图片地址
      full_image:'', //图片页面显示地址

      Row:{
        realname:'',     //姓名
        Eurl:'',         //头像
        mobile:'',       //手机号
        job:'',          //职务
        job_number:'',   //工号
        department_name:'',
        department_shangji:'',
        email:'',        //邮箱
        entry_time:'', //入职时间
        sex_text:'',     //性别
        identity_text:'', //员工角色
        work_year: '',    //工作年限
        F_ull_image:'',    //图片地址
        mobile_hide:0,
      },
      Row2:{
        quit_time_text:'', //入职时间
        quit_time:'', //入职时间
        reason:'',    //离职原因
        type:"",      //离职类型:10=主动离职,20=被动离职
        type_text:'',
        quit_status:'',  //办理状态:10=待交接,20=已交接,30=不需交接
        quit_status_text:'',
        is_transfer:null,

        transfer: [
            {
              name:'订单资源',
              people:'转移所有档案',
              type: "order", //离职类型:10=主动离职,20=被动离职
              to_staff_id: 0, //接收人
              to_staff:{
                realname:'',    //接收人
              }
            },
            {
              name:'订单资源',
              people:'转移所有档案',
              type: "customer", //离职类型:10=主动离职,20=被动离职
              to_staff_id: 0, //接收人
              to_staff:{
                realname:'',    //接收人
              }
              
            },
            {
              name:'订单资源',
              people:'转移所有档案',
              type: "file", //离职类型:10=主动离职,20=被动离职
              to_staff_id: 0, //接收人
              to_staff:{
                realname:'',    //接收人
              }
            }
          ] //转移资源，is_transfer=1时显示
      },
      Row3: {        // 新增字段
        name: undefined, //字段名称
        title: undefined, //字段标题
        tip: "", //提示语
        type: "", //字段类型
        content: "", //选项内容
        is_require: 0, //是否必填项:1=是,0=否
        is_show: 0, //是否员工可见:1=是,0=否
        is_edit: 0, //是否可编辑:1=是,0=否
        status: "normal" //启用状态，hidden=未启用,normal=已启用
      },
      Type_List:['日期','日期时间','文件','图片','数字','列表','文本',],
      Type_List_text:'',
      R3_inou:[{name:'选项1',content:''},{name:'选项2',content:''}],

      selection_arr: null,     // 选中离职员工数组
      atat:0,
      atat2:0,
      input_seach:''
    };
  },
  mounted: function() {
    var that = this
        let token = window.localStorage.getItem("accessToken")
        that.heads.token = token

        axios.get('/admin/company/staff/fieldsList',{   // 字段列表
            params:{
              page: 1,
              offset:0,
              limit:10,
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.ZMent = res.data.data.rows
              that.Type_List_text = res.data.data.rows.type
              // that.Type_List = res.data.data.typeList
              that.total1 = res.data.data.total
            }
        })
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    Close(e) {
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
    },
    handleEdit2(e) {
      console.log(e.row.inpu)
    },



    Add_kq(e) {
      var that = this
      if (e == 1) {
        that.show1 = true
        that.XX = 'xin'
        that.Fond_tag = null
        that.Row = {}
        that.Row.mobile_hide = 0
        // that.Row.department.name = '222'
      } else if(e == 2){
        that.Row3 = {}
        that.Type_List_text = ''
        that.R3_inou = [{name:'选项1',content:''},{name:'选项2',content:''}]
        that.dialogVisible2 = true;
        that.Ad2_ziduan = 'xin'
      } else if (e == 3) {
        that.show1 = false
        that.show3 = true
      } else if(e == 4) {
        that.editVisible = true
        that.fForm = {}
        that.Bmen_Sj1 = ''
        that.Bmen_Sj2 = ''
        that.Bmmm = false
      }else if(e == 5) {
        that.editVisible = true
        that.fForm = {}
        that.Bmen_Sj1 = ''
        that.Bmen_Sj2 = ''
        that.Bmmm = true
      }
    },
  
    handleSelect(key, keyPath) {  // 上访切换
      var that = this
      console.log(key, keyPath);
      if (key == 4) {
        this.$router.push('/Manage/BaseTable4');
      } else if (key == 1) {
        this.$router.push('/Manage/BaseTable');
      } else if(key == 2) {
        this.$router.push('/Manage/BaseTable2');
        // axios.get('/admin/company/department/departmentList',{   
        //   }).then(function (res) {
        //     if (res.code == 0) {
        //           that.$message.warning(res.data.msg);
        //     } else{ 
        //       that.BMent = res.data.data.rows
        //       // that.total2 = res.data.data.total
        //       console.log(that.BMent)
        //     }
        // })
      }
    },
    inpu_sech() {             // 搜索
      var that = this
      axios({
        method: 'post',
        url: '/admin/company/staff/fieldsList', 
        data: {
          page: 1,
          offset:0,
          limit:10,
          filter:{
              keyword:that.input_seach
          },
          
          
        },
      }).then(function (res) {
              that.ZMent = res.data.data.rows
              that.total1 = res.data.data.total
      })   
    },
  
    handleCurrentChange1(val) {    // 分页 ···············································
      var that = this
      that.tot = val
      console.log(val)
      that.currentPage1 = val
        axios.get('/admin/company/staff/fieldsList',{   // 字段列表
            params:{
              page: val,
              offset:0,
              limit:10,
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.ZMent = res.data.data.rows
              that.Type_List_text = res.data.data.rows.type
              that.total1 = res.data.data.total
            }
        })

    },

//SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS----------------------------------------------新增字段------------------------------SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS-----------------------
    Del_xinz(e) {    // 删除字段
    var that = this
          that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          axios.delete('/admin/company/staff/delFields',{   // 模块
            params:{
                  id: e.id
            }
          }).then(function (res) {
          if (res.code == 0) {
                that.$message.warning(res.data.msg);
          } else{ 
              that.$message.success(res.data.msg);
              axios.get('/admin/company/staff/fieldsList',{   // 字段列表
                  params:{
                    page: 1,
                    offset:0,
                    limit:10,
                  }
                }).then(function (res) {
                  if (res.code == 0) {
                    alert('请求失败');  
                  } else{ 
                    that.ZMent = res.data.data.rows
                    // that.Type_List = res.data.data.typeList
                    that.total3 = res.data.data.total
                  }
              })
          }
        })
        })
    },  
    Din_xinz(e) {      // 停用  //启用
    console.log(e)
     var that = this
     if (e.status == 'normal') {
        e.status = 'hidden'
        console.log(e)
            axios({
              method: 'put',
              url: '/admin/company/staff/editFields',
              data: {
                id: e.id,
                row: e
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {

                }
            })      
     } else if(e.status == 'hidden'){
        e.status = 'normal'
                    axios({
              method: 'put',
              url: '/admin/company/staff/editFields',
              data: {
                id: e.id,
                row: e
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {

                }
            })  
     }
      that.Dy = false
    },
    Edit_xinz(e) {   //编辑
      var that = this
      that.Ad2_ziduan = ''
      console.log(e)
      that.dialogVisible2 = true
      that.ziduan_id = e.id
            
        axios.get('/admin/company/staff/fieldsInfo',{   // 模块
          params:{
              id:e.id
          }
          }).then(function (res) {
            if (res.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
              that.Row3 = res.data.data.row
              that.R3_inou = res.data.data.row.content
              var arr = res.data.data.row.type
              console.log(that.Row3)
              if (arr == 'date') {
                that.Type_List_text = '日期'
              }else if(arr == 'datetime') {
                that.Type_List_text = '日期时间'
              } else if(arr == 'datetime') {
                that.Type_List_text = '文件'
              } else if(arr == 'image') {
                that.Type_List_text = '图片'
              } else if(arr == 'number') {
                that.Type_List_text = '数字'
              } else if(arr == 'select') {
                that.Type_List_text = '列表'
              } else if(arr == 'string') {
                that.Type_List_text = '文本'
              } else if(arr == 'text') {
                that.Type_List_text = '文本'
              }
            }
        })
    },
    Add_cent() {    //新增选项内容
      var that = this
      var arr = that.R3_inou
      var num = 0
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        num = i + 2
      }
      console.log(num)
      var att = {
        name:'选项' + num,
        content:''
      }
      arr.push(att)
      that.R3_inou = arr

    },
    BTN_ziduan(e) {    //新增编辑字段确认
        var that = this
            console.log(that.Row3)
        if (that.Row3.name == undefined || that.Row3.name == '') {
          that.$message.warning('字段名称不能为空');
        } else if(that.Row3.title == undefined || that.Row3.title == '') {
          that.$message.warning('字段标题不能为空');
        } else{
          if (e == 1) {
            axios({ 
              method: 'put',
              url: '/admin/company/staff/editFields',    
              data: {
                id:that.ziduan_id,
                row:{
                  name: that.Row3.name, //字段名称
                  title: that.Row3.title, //字段标题
                  tip: that.Row3.tip, //提示语
                  type: that.Row3.type, //字段类型
                  content: that.R3_inou, //选项内容
                  is_require: that.Row3.is_require, //是否必填项:1=是,0=否
                  is_show: that.Row3.is_show, //是否员工可见:1=是,0=否
                  is_edit: that.Row3.is_edit, //是否可编辑:1=是,0=否
                  status: "normal" //启用状态，hidden=未启用,normal=已启用
                }
              },
              
            }).then(function (res) {
                if (res.data.code == 0) {
                  
                  that.$message.warning(res.data.msg);
                } else {
                  that.$message.success(res.data.msg);
                  that.dialogVisible2 = false
                  // that.$message.success(res.data.msg);
                  axios.get('/admin/company/staff/fieldsList',{   // 字段列表
                      params:{
                        page: 1,
                        offset:0,
                        limit:10,
                      }
                    }).then(function (res) {
                      if (res.code == 0) {
                        alert('请求失败');  
                      } else{ 
                        that.ZMent = res.data.data.rows
                        that.total3 = res.data.data.total
                      }
                  })
                }

            }) 
          } else {
            axios({ 
              method: 'post',
              url: '/admin/company/staff/addFields',    
              data: {
                
                row:{
                  name: that.Row3.name, //字段名称
                  title: that.Row3.title, //字段标题
                  tip: that.Row3.tip, //提示语
                  type: that.Row3.type, //字段类型
                  content: that.R3_inou, //选项内容
                  is_require: that.Row3.is_require, //是否必填项:1=是,0=否
                  is_show: that.Row3.is_show, //是否员工可见:1=是,0=否
                  is_edit: that.Row3.is_edit, //是否可编辑:1=是,0=否
                  status: "normal" //启用状态，hidden=未启用,normal=已启用
                }
              },
              
            }).then(function (res) {
                if (res.data.code == 0) {
                  
                  that.$message.warning(res.data.msg);
                } else {
                  that.$message.success(res.data.msg);
                  that.dialogVisible2 = false

                  axios.get('/admin/company/staff/fieldsList',{   // 字段列表
                      params:{
                        page: 1,
                        offset:0,
                        limit:10,
                      }
                    }).then(function (res) {
                      if (res.code == 0) {
                        alert('请求失败');  
                      } else{ 
                        that.ZMent = res.data.data.rows
                        that.total3 = res.data.data.total
                      }
                  })
                }

            }) 
          }

      }

    },
    Type_Chan(e) {    //字段类型改变
      console.log(e)
      var that = this
      if (e == '日期') {
        that.Row3.type = 'date'
      } else if (e == '日期时间') {
        that.Row3.type = 'datetime'
      } else if (e == '文件') {
        that.Row3.type = 'file'
      } else if (e == '图片') {
        that.Row3.type = 'image'
      } else if (e == '数字') {
        that.Row3.type = 'number'
      } else if (e == '列表') {
        that.Row3.type = 'select'
      } else if (e == '文本' || e == 'text') {
        that.Row3.type = 'string'
      } 
    },
    Switch1(e) {
      console.log(e)
      var that = this
            axios({
              method: 'put',
              url: '/admin/company/staff/editFields',
              data: {
                id: e.id,
                row: e
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {

                }
            })
    },
    Switch2(e) {
      console.log(e)
      var that = this
            axios({
              method: 'put',
              url: '/admin/company/staff/editFields',
              data: {
                id: e.id,
                row: e
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {

                }
            })
    },
    Switch3(e) {
      console.log(e)
      var that = this
            axios({
              method: 'put',
              url: '/admin/company/staff/editFields',
              data: {
                id: e.id,
                row: e
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {

                }
            })
    },
    handleCurrentChange3(val) {    // 分页 ···············································
      var that = this
      that.tot = val
      console.log(val)
      that.currentPage3 = val
      console.log(that.currentPage3)
        axios.get('/admin/company/staff/fieldsList',{   // 字段列表
          params:{
              page: val,
              limit: 10
          } 
          }).then(function (res) {
            if (res.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
              that.total3 = res.data.data.total
              that.ZMent = res.data.data.rows
              console.log(that.ZMent)
            }
        })


    },
//EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE--------------------------------------------新增字段---------------------EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE------------------------------------

    callback() {
      var that = this
      that.$message.warning('请输入正确的电话号码或邮箱');
      that.FAFA_l = false
    },
    callback2() {
      var that = this
      that.$message.warning('请输入正确的电话号码或邮箱');
      that.FAFA_2 = false
    }
  },

};
</script>


<style scoped>
.BaseTable4 .Peop {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1890FF!important;
  float: right;
  margin-right: 22px;
  cursor: pointer;
}   
.BaseTable4 .Xians {
  margin-bottom: 11px;
  overflow: hidden;
}

.BaseTable4 .dsdsd .el-input {
    margin-bottom: 20px;
}
.BaseTable4 .Icci {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1890FF;
  cursor: pointer;
  margin: 10px 0;
}
.BaseTable4 .Icci span {
  margin-left: 5px;
}
.BaseTable4 .dwwwasd {
  font-size: 14px; 
  font-weight: 400;
  color: #262626;
  float: left;
  margin-right: 10px;
}  

.BaseTable4 .isddss {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin-right: 14px;
  float: left;
  margin-top: 7px;
}
</style>
